@tailwind base;
@tailwind components;
@tailwind utilities;

/*Toast open/load animation*/
.alert-toast {
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/*Toast close animation*/
.alert-toast input:checked ~ * {
    -webkit-animation: fade-out-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: fade-out-right 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* -------------------------------------------------------------
 * Animations generated using Animista * w: http://animista.net,
 * ---------------------------------------------------------- */

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
}

@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
}

@-webkit-keyframes fade-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0
    }
}

@keyframes fade-out-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    100% {
        -webkit-transform: translateX(50px);
        transform: translateX(50px);
        opacity: 0
    }
}

#room-container {
    width: 100%;
    background-color: #272727;
    display: grid;
    height: calc(100vh - 54px);
    grid-gap: 6px 6px;
    grid-template-columns: repeat(auto-fit, minmax(320px, auto));
    grid-template-rows: repeat(auto-fit, minmax(100px, auto));
}

#room-container .clock {
    text-shadow: 0 0 20px rgb(84, 84, 93),  0 0 20px rgba(173, 187, 190, 0);
}

video {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.display-media {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

#room-container.fullscreen {
    flex: 1 1;
}

#room-container.fullscreen > div {
    display: none;
}

#room-container.fullscreen > div.fullscreen {
    display: flex;
}

@keyframes target {
    from { background:#ffffff; }
    50% { background:#ffffd3; }
    to { background:#ffffff; }
}

@-webkit-keyframes target {
    from { background:#ffffff; }
    50% { background:#ffffd3; }
    to { background:#ffffff; }
}

.selected-comment {
    border:1px solid #dac89d;
    -webkit-animation:target .5s 2 linear;
}